import { fieldParams, postData, querySearch } from '@utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        postData(querySearch(queryParams, 'adminProductList'))
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchProduct(ctx, productData) {
      return new Promise((resolve, reject) => {
        postData(productData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addUpdateProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        postData(fieldParams(data.data, ['baslik', 'kisa_aciklama'], 'saveProduct', data.id))
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteProduct(ctx, productData) {
      return new Promise((resolve, reject) => {
        postData(productData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    productImageDelete(ctx, productData) {
      return new Promise((resolve, reject) => {
        postData(productData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
